import {useQuery} from "@tanstack/react-query";
import {fetchUserStats} from "@/api/perpsDataFetcher";
import React from "react";
import {ErrorPanel} from "@/components/ErrorPanel";
import {SummaryItem} from "@/components/Perps/SummaryItem";
import {UserStats} from "@/components/Perps/types";
import {AddressDisplay} from "@/components/Account/AddressDisplay";
import * as Tooltips from "@/util/tooltips";
import {EthValue} from "@/components/Perps/EthValue";
import {useEthPrice} from "@/contexts/EthPriceContext";

export const TraderProfilePopup = ({address}: {address: string}) => {
  const query = useQuery({
    queryKey: ["user-stats", address],
    queryFn: async () => await fetchUserStats(address!),
    refetchInterval: 10 * 60 * 1000,
  });
  const {ethPrice, showInUsd} = useEthPrice();

  return (
    <div className="standard-stack">
      <SummaryItem<UserStats>
        label="Account"
        className="text-sm text-white"
        isLoading={query.isLoading}
        data={query.data}
        isError={query.isError}
      >
        {v =>
          <AddressDisplay
            short={true}
            blockieSize={5}
            address={v.address}
            displaySelf={false}
            showAvatar={true}
            useBlockie={true}
          />
        }
      </SummaryItem>
      <hr className="border-neutral-content/50 w-full"/>
      <SummaryItem<UserStats>
        label="PnL"
        className="text-sm text-white"
        isLoading={query.isLoading}
        data={query.data}
        isError={query.isError}
      >
        {stats =>
          <EthValue
            value={BigInt(stats.totalPnL)}
            valueForPercent={BigInt(stats.totalPnL) + BigInt(stats.totalDownPayment)}
            original={BigInt(stats.totalDownPayment)}
            showInUsd={showInUsd}
            ethPrice={ethPrice}/>
        }
      </SummaryItem>
      <hr className="border-neutral-content/50 w-full"/>
      <SummaryItem<UserStats>
        label="Volume"
        className="text-sm text-white"
        isLoading={query.isLoading}
        data={query.data}
        isError={query.isError}>
        {stats => <EthValue value={stats.totalVolume} ethPrice={ethPrice} showInUsd={showInUsd}/>}
      </SummaryItem>
    </div>
  )
}